import React from "react";
import { Col, Row } from "react-bootstrap";
const Stats = (props) => {
  return (
    <div className="dashboard-boxes">
      <div className="dashboard-boxes-info">
        <Row>
          <Col xxl="3" xl="4" md="6" className="mb-4">
            <div className="dashboard-boxes-content">
              <h3 className="text-green">{props?.stats?.approved}</h3>
              <p>Total Approved GatePass</p>
            </div>
          </Col>
          <Col xxl="3" xl="4" md="6" className="mb-4">
            <div className="dashboard-boxes-content">
              <h3 className="text-red">{props?.stats?.rejected}</h3>
              <p>Total Rejected GatePass</p>
            </div>
          </Col>
          {(props.userRole === "1" ||
            props.userRole === "4" ||
            props.userRole === "5") && (
            <Col xxl="3" xl="4" md="6" className="mb-4">
              <div className="dashboard-boxes-content">
                <h3 className="text-blue">{props?.stats?.pending}</h3>
                <p>Pending on Approvals</p>
              </div>
            </Col>
          )}
          {(props.userRole === "1" ||
            props.userRole === "4" ||
            props.userRole === "5") && (
            <Col xxl="3" xl="4" md="6" className="mb-4">
              <div className="dashboard-boxes-content">
                <h3 className="text-blue">{props?.stats?.completed}</h3>
                <p>Closed Requests</p>
              </div>
            </Col>
          )}

          <Col xxl="3" xl="4" md="6" className="mb-4">
            <div className="dashboard-boxes-content">
              <h3 className="text-blue">{props?.stats?.all}</h3>
              <p>Total Generated GatePass</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Stats;
