import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Notification from "../Notifications";
import { useNavigate, useLocation } from "react-router-dom";
const Private = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  });

  return (
    <>
      {
        location.pathname.split("/")[1] != "profile" ?
        <div className="dashboard-wrapper position-relative">
          <Sidebar />
          <div className="dashboard-main">
            <Notification />
            {children}
          </div>
        </div>
        :
        children
      }
    </>
  );
};

export default Private;
