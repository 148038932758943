import React, { useState, useEffect } from "react";
import baseURL from "../helpers/baseURL";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button, Col, Row, Image, Badge } from "react-bootstrap";
import MetroIcon from "../../assets/images/metro-logo.png";
import PencilIcon from "../../assets/images/pencil-icon.svg";
import Approval from "../Approval";
import Moment from "moment";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const GatepassView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const { id } = useParams();
  const [gate_pass_id, setGatePassId] = useState(id);
  const [supplier_stock, setSupplierStock] = useState(0);
  const [reason, setReason] = useState(0);
  const [reason_description, setReasonDescription] = useState("");
  // Show specific gate pass
  const [gatePass, setGatePass] = useState([]);
  const [gatePassCategory, setGatePassCategory] = useState([]);
  const [Approved, setGatePassApproved] = useState([]);
  const [gatePassApprovers, setGatePassApprovers] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchGatePasses = async () => {
      setIsLoading(true);
      baseURL
        .get(`/gatePass/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setGatePassCategory(res.data.data.category);
          setGatePassApprovers(res.data.data.approvers);
          setGatePass(res.data.data);
          setIsLoading(false);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchGatePasses();
  }, [Approved, id]);
  // supplier stock check
  const supplierStockChange = (e) => {
    supplier_stock == 0 ? setSupplierStock(1) : setSupplierStock(0);
  };

  // other reason check
  const ReasonChange = (e) => {
    reason == 0 ? setReason(1) : setReason(0);
  };
  const changeReasonDescription = (e) => {
    setReasonDescription(e);
  };
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // Approve or Rejected Handler
  const HandleGatePassApproval = (status) => {
    if (status == 2 && reason_description == "") {
      setReason(1);
      toast.error("Please give the reason for rejection");
    } else if (reason_description == "" && reason == 1 && status == 3) {
      toast.error("Please give the reason");
    } else {
      setButtonDisabled(true); // Disable the button
      baseURL
        .post(
          `/gatePass/approver`,
          {
            supplier_stock,
            reason_description,
            gate_pass_id,
            status
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((res) => {
          if (res.data.status === "Success") {
            setGatePassApproved(res?.data?.data);
            toast.success(res.data.message);
            setReason(0);
            setReasonDescription("");
          }
        })
        .catch((res) => {
          toast.error(
            res?.response?.data != null
              ? (res?.response?.data?.data ? res?.response?.data?.data[0] : res?.response?.data?.message)
              : "something went wrong"
          );
        }).finally(() => {
          setButtonDisabled(false); // Re-enable the button
        });
    }
  };

  const gate_pass_data = [
    [
      Moment(gatePass?.created_at).format("DD-MM-YYYY"),
      gatePass?.vehicle_no,
      gatePassCategory?.parent
        ? gatePassCategory?.parent?.title + " - " + gatePassCategory?.title
        : gatePassCategory?.title,
      gatePass.issued_to?.name,
      gatePass.company_name,
      gatePass.department,
      gatePass.status
    ]
  ];
  const gate_data = [
    [
      "Created at",
      "Vehicle No",
      "Gatepass Type",
      "Issued To",
      "Company Name",
      "Department",
      "Status"
    ]
  ];
  gate_data.push(...gate_pass_data);
  // Export to Excel
  const exportToExcel = (tableData) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    const dataWithoutId = tableData.map(({ id, ...rest }) => rest);
    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataWithoutId);
    const worksheet1 = XLSX.utils.aoa_to_sheet(gate_data);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Item Detail");
    XLSX.utils.book_append_sheet(workbook, worksheet1, "GatePass Detail");
    // Generate an XLSX file buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });

    // Convert the buffer to a Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    // Save the file
    saveAs(blob, "table_data.xlsx");
  };

  // Section for roles and permissions
  const [user, setUser] = useState([]);
  const [allPermissions, setAllPermissions] = useState("");
  useEffect(() => {
    const fetchNameAndRole = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUser(res?.data?.data);
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };

    const fetchPermissions = async () => {
      baseURL
        .get(`/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllPermissions(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchPermissions();
    fetchNameAndRole();
  }, []);
  // Get Permissions of Login User
  const permissions =
    allPermissions &&
    allPermissions.filter((item) => user?.role?.permissions.includes(item.id));
  return isLoading ? (
    <div className="loader-area">
      <div className="loader-wrapper">
        <SpinnerRoundOutlined
          size={50}
          thickness={100}
          speed={100}
          color="#1239A5"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    </div>
  ) : (
    <div className="listing-metro-details">
      <div className="dashboard-main-heading-area dashboard-main-heading-mobile mb-4 d-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 mb-3">GatePass</h4>
        <div className="generate-buttons">
          <Button className="btn-gray me-3 mb-sm-0 mb-3" onClick={handleCancel}>
            Back
          </Button>
          <Button
            className="btn-yellow"
            onClick={() => exportToExcel(gatePass.items)}
          >
            Download
          </Button>
        </div>
      </div>
      <Row>
        <Col xxl="8" xl="12" className="mb-xxl-0 mb-5">
          <div className="listing-metro-wrapper">
            <div className="listing-metro">
              <div className="listing-metro-img-area d-flex align-items-center justify-content-between">
                <a href="/dashboard" className="listing-metro-img">
                  <Image
                    src={MetroIcon}
                    className="img-fluid"
                    alt="metro-icon"
                  />
                </a>
                {permissions &&
                  permissions.filter(
                    (permission) => permission.name === "Edit GatePass"
                  ).length > 0 && (
                    <Link
                      to={`/edit-gatepass/${gatePass.id}`}
                      className="pencil-icon-img"
                    >
                      <Image
                        src={PencilIcon}
                        className="img-fluid"
                        alt="pencil-icon"
                      />
                    </Link>
                  )}
              </div>
              <div className="gatepass-status d-flex align-items-center justify-content-between">
                <h5>{"GatePass #" + gatePass.id}</h5>
                <div class="generate-form-top-info">
                  <h6>
                    Status:
                    {/* <span class="span-badge">Inprogress</span> */}
                    {gatePass.status == 0 && (
                      <Badge className="badge-orange">Pending</Badge>
                    )}
                    {gatePass.status == 1 && (
                      <Badge className="badge-purple">In Approval</Badge>
                    )}
                    {gatePass.status == 2 && (
                      <Badge className="badge-sky-blue">Approved</Badge>
                    )}
                    {gatePass.status == 3 && (
                      <Badge className="badge-pink">Rejected</Badge>
                    )}
                    {gatePass.status == 4 && (
                      <Badge className="badge-green">Completed</Badge>
                    )}
                  </h6>
                </div>
              </div>
              <div className="listing-metro-details">
                <Row>
                  <Col lg="6">
                    <div className="listing-metro-details-content">
                      <label>Date:</label>
                      <h6>
                        {Moment(gatePass?.created_at).format("DD-MM-YYYY")}
                      </h6>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="listing-metro-details-content">
                      <label>Vehicle No</label>
                      <h6>{gatePass?.vehicle_no}</h6>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="listing-metro-details-content">
                      <label>GatePass Type</label>
                      <h6>
                        {gatePassCategory?.parent
                          ? gatePassCategory?.parent?.title +
                            " - " +
                            gatePassCategory?.title
                          : gatePassCategory?.title}
                      </h6>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="listing-metro-details-content">
                      <label>Issued To</label>
                      <h6>{gatePass.issued_to?.name}</h6>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="listing-metro-details-content mb-0">
                      <label>Company Name & Department</label>
                      <h6>
                        {gatePass.company_name} <br />
                        <br /> {gatePass.department}
                      </h6>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="generate-form-bottom-content listing-metro-descrition">
              <div className="generate-form-bottom-scroll-wrapper">
                <div className="generate-form-bottom-scroll">
                  <div className="generate-form-bottom-content-info position-relative d-flex align-items-center justify-content-between">
                    <div className="serial-number">
                      <label>Sr No.</label>
                    </div>
                    <div className="bottom-inputs-area d-flex align-items-center justify-content-between">
                      <div className="generate-description">
                        <label>Description</label>
                      </div>
                      <div className="generate-quantity">
                        <label>Quantity</label>
                      </div>
                      <div className="generate-remarks">
                        <label>Remarks</label>
                      </div>
                    </div>
                  </div>
                  {gatePass.items &&
                    gatePass.items.map((row, key) => (
                      <div className="generate-form-bottom-content-info position-relative d-flex align-items-center justify-content-between">
                        <div className="serial-number">
                          <span className="d-block">{key + 1}</span>
                        </div>
                        <div className="bottom-inputs-area d-flex align-items-center justify-content-between">
                          <div className="generate-description">
                            <span className="d-block">{row.description}</span>
                          </div>
                          <div className="generate-quantity">
                            <span className="d-block">{row.quantity}</span>
                          </div>
                          <div className="generate-remarks">
                            <span className="d-block">{row.remarks}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl="4" xl="12">
          <Approval
            gatePassId={id}
            HandleGatePassApproval={HandleGatePassApproval}
            Approved={Approved}
            supplierStockChange={supplierStockChange}
            supplier_stock={supplier_stock}
            reason={reason}
            ReasonChange={ReasonChange}
            reason_description={reason_description}
            changeReasonDescription={changeReasonDescription}
            gatePass={gatePass}
            gatePassApprovers={gatePassApprovers}
            buttonDisabled={buttonDisabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GatepassView;
