import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Form,
  Col,
  Image,
  Modal,
  Row,
  Table
} from "react-bootstrap";
import PencilIcon from "../../assets/images/pencil-icon.svg";
import TrashIcon from "../../assets/images/trash-icon.svg";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { SpinnerRoundOutlined } from "spinners-react";
const RolesPermissionTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [page, setPage] = useState("");
  // create or edit modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setPage(null);
    setRoleId(null);
    setName(null);
    setStatus(1)
    setSelectedOptions(null);
    setPermissions([])
    setShow(true);
    console.log(permissions,"permissions")
  };
  // delete modal
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  // Role & Permissions
  const [role, setRole] = useState([]);
  const [name, setName] = useState("");
  const [user, setUser] = useState([]);
  const [showPermissions, setShowPermissions] = useState([]);
  const [status, setStatus] = useState(1);
  // get all roles
  const [roles, setRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState("");
  useEffect(() => {
    setIsLoading(true);
    const fetchRoles = async () => {
      baseURL
        .get(`/roles`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setRoles(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    const fetchPermissions = async () => {
      baseURL
        .get(`/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllPermissions(res.data.data);
          setIsLoading(false);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    const fetchNameAndRole = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUser(res?.data?.data);
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };
    fetchRoles();
    fetchPermissions();
    fetchNameAndRole();
  }, [role]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [editPermissions, setEditPermissions] = useState([]);
  const onSelect = (selectedList, selectedItem) => {
    const selectedValues = selectedList.map((option) => option.id);
    setPermissions(selectedValues);
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList, selectedItem) => {
    const selectedValues = selectedList.map((option) => option.id);
    setPermissions(selectedValues);
    setSelectedOptions(selectedList);
  };
  // Create Role Handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    if(permissions.length == 0)
    {
      toast.error("Please choose atleast one permission")
      return;
    }
    await baseURL
      .post(
        `/roles`,
        {
          name,
          permissions,
          status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(res.data.message);
          setRole(res.data.data);
          setShow(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };

  // set states in case of edit & delete
  const [roleId, setRoleId] = useState([]);
  const handleChangeCategory = (api, id, name, permissions, status) => {
    setPage(api);
    setRoleId(id);
    setName(name);
    setStatus(status);
    setPermissions(permissions);
    // setPermissions(permissions.split(","));
    if (permissions) {
      const response = allPermissions.filter((item) =>
        permissions.includes(item.id)
      );
      setSelectedOptions(response);
    } else {
      setSelectedOptions(null);
    }
    api != "delete" &&
      (Array.isArray(permissions)
        ? setEditPermissions(permissions)
        : setEditPermissions(permissions.split(",").map(Number)));
    
    api === "update" ? setShow(true) : setShowModal(true);
  };
  useEffect(() => {
    setPermissions(editPermissions);
  }, [editPermissions]);
  // update User Handler
  const handleUpdateRole = async (event) => {
    event.preventDefault();
    if(permissions.length == 0)
    {
      toast.error("Please choose atleast one permission")
      return;
    }
    await baseURL
      .put(
        `/roles/${roleId}`,
        {
          name,
          permissions,
          status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(res.data.message);
          setRole(res.data.data);
          setShow(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };
  // Delete role Handler
  const handleDeleteCategory = async (event) => {
    event.preventDefault();
    await baseURL
      .delete(`/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(res.data.message);
          setRole(res.data.data);
          setShowModal(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };

  // Get Permissions of Login User
  const login_user_permissions =
    allPermissions &&
    allPermissions.filter((item) => user?.role?.permissions?.includes(item.id));
  console.log(roleId == 7, "roleId");
  return isLoading ? (
    <div className="loader-area">
      <div className="loader-wrapper">
        <SpinnerRoundOutlined
          size={50}
          thickness={100}
          speed={100}
          color="#1239A5"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    </div>
  ) : (
    <div className="dashboard-table">
      <div className="dashboard-main-heading-area dashboard-main-heading-mobile mb-md-5 mb-4 pt-0 d-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 mb-3">Roles & Permissions</h4>
        {login_user_permissions &&
          login_user_permissions.filter(
            (permission) => permission.name === "Add Role"
          ).length > 0 && (
            <Button className="btn-yellow" onClick={handleShow}>
              Add New Role
            </Button>
          )}
      </div>
      <div className="dashboard-table">
        <div className="dashboard-table-content">
          <div className="dashboard-table-info">
            <Table>
              <thead>
                <tr>
                  <th>Role Title</th>
                  <th>Permissions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {roles &&
                  roles.map((row, key) => (
                    <tr>
                      <td className="td-width">{row?.name}</td>
                      <td className="td-badges-wrapper">
                        <div className="table-badges">
                          {row?.permissions &&
                            allPermissions &&
                            allPermissions
                              .filter((item) =>
                                row?.permissions.includes(item.id)
                              )
                              .map((row, key) => <Badge>{row?.name}</Badge>)}
                        </div>
                      </td>

                      <td className="td-width">
                        <div className="edit-table-icons d-flex align-items-center">
                          {login_user_permissions &&
                            login_user_permissions.filter(
                              (permission) => permission.name === "Edit Role"
                            ).length > 0 && (
                              <a href="#" className="eye-icon me-4">
                                <Image
                                  src={PencilIcon}
                                  className="img-fluid"
                                  alt="aye-icon"
                                  onClick={() =>
                                    handleChangeCategory(
                                      "update",
                                      row.id,
                                      row?.name,
                                      row?.permissions,
                                      row?.status
                                    )
                                  }
                                />
                              </a>
                            )}
                          {user?.role?.id == 7 &&
                          login_user_permissions &&
                          login_user_permissions.filter(
                            (permission) => permission.name === "Delete Role"
                          ).length > 0 ? (
                            <a href="#" className="trash-icon">
                              <Image
                                src={TrashIcon}
                                className="img-fluid"
                                alt="trash-icon"
                                onClick={() =>
                                  handleChangeCategory("delete", row.id)
                                }
                              />
                            </a>
                          ) : (
                            row?.id > 7 && (
                              <a href="#" className="trash-icon">
                                <Image
                                  src={TrashIcon}
                                  className="img-fluid"
                                  alt="trash-icon"
                                  onClick={() =>
                                    handleChangeCategory("delete", row.id)
                                  }
                                />
                              </a>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {/* create or edit role Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        className="category-modal d-flex align-items-center justify-content-center"
      >
        <Form onSubmit={page === "update" ? handleUpdateRole : handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {page === "update" ? "Update Role" : "Add New Role"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="category-modal-content">
              <Row>
                <Col lg="12">
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Name <span className="text-red">*</span>
                    </Form.Label>
                    {user?.role?.id == 7 &&
                    login_user_permissions &&
                    login_user_permissions.filter(
                      (permission) => permission.name === "Edit Role"
                    ).length > 0 ? (
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    ) : roleId && roleId < 7 ? (
                      <Form.Control
                        type="text"
                        value={name}
                        readOnly
                        required
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col lg="12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      Permissions <span className="text-red">*</span>
                    </Form.Label>
                    <Multiselect
                      options={allPermissions}
                      selectedValues={selectedOptions}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Status <span className="text-red">*</span>
                </Form.Label>
                {user?.role?.id == 7 &&
                login_user_permissions &&
                login_user_permissions.filter(
                  (permission) => permission.name === "Edit Role"
                ).length > 0 ? (
                  <div className="form-radios d-flex align-items-center">
                    <div className="form-radio-content me-5 d-flex align-items-center">
                      <Form.Check
                        name="status"
                        type="radio"
                        id="active"
                        aria-label="radio 1"
                        value="1"
                        checked={status == 1}
                        onChange={(e) => setStatus(1)}
                      />
                      <Form.Label>Active</Form.Label>
                    </div>
                    <div className="form-radio-content d-flex align-items-center">
                      <Form.Check
                        name="status"
                        type="radio"
                        id="inactive"
                        aria-label="radio 1"
                        value="0"
                        checked={status == 0}
                        onChange={(e) => setStatus(0)}
                      />
                      <Form.Label>Inactive</Form.Label>
                    </div>
                  </div>
                ) : roleId && roleId < 7 ? (
                  <div className="form-radios d-flex align-items-center">
                    <div className="form-radio-content me-5 d-flex align-items-center">
                      <Form.Check
                        name="status"
                        type="radio"
                        id="active"
                        aria-label="radio 1"
                        value="1"
                        checked={status == 1}
                      />
                      <Form.Label>Active</Form.Label>
                    </div>
                    <div className="form-radio-content d-flex align-items-center">
                      <Form.Check
                        name="status"
                        type="radio"
                        id="inactive"
                        aria-label="radio 1"
                        value="0"
                        checked={status == 0}
                      />
                      <Form.Label>Inactive</Form.Label>
                    </div>
                  </div>
                ) : (
                  <div className="form-radios d-flex align-items-center">
                    <div className="form-radio-content me-5 d-flex align-items-center">
                      <Form.Check
                        name="status"
                        type="radio"
                        id="active"
                        aria-label="radio 1"
                        value="1"
                        checked={status == 1}
                        onChange={(e) => setStatus(1)}
                      />
                      <Form.Label>Active</Form.Label>
                    </div>
                    <div className="form-radio-content d-flex align-items-center">
                      <Form.Check
                        name="status"
                        type="radio"
                        id="inactive"
                        aria-label="radio 1"
                        value="0"
                        checked={status == 0}
                        onChange={(e) => setStatus(0)}
                      />
                      <Form.Label>Inactive</Form.Label>
                    </div>
                  </div>
                )}
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-gray" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn-yellow" type="submit">
              {page === "update" ? "Update Role" : "Add Role"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Delete role Modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        className="profile-modal d-flex align-items-center justify-content-center"
      >
        <Modal.Body>
          <h2>Confirm</h2>
          <h4>Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-gray" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button className="btn-gray" onClick={handleDeleteCategory}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RolesPermissionTable;
