import React, { useState, useEffect } from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "react-bootstrap";
import Approval from "../Approval";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "moment";
const GenerateForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const page = id ? "Edit" : "Generate";
  const token = localStorage.getItem("token");
  const handleCancel = () => navigate("/dashboard");
  // Generate Gatepass States
  const [vehicle_no, setVehicleNo] = useState([]);
  const [generated_by, setGeneratedBy] = useState([]);
  const [store_id, setStoreId] = useState([]);
  const [category_id, setCategoryId] = useState([]);
  const [issued_to, setIssuer] = useState([]);
  const [company_name, setComapnyName] = useState("");
  const [department, setDepartment] = useState("");
  // Set Array Items State
  const [items, setItems] = useState([
    { description: "", quantity: "", remarks: "" }
  ]);

  // Add a new row
  const addItem = () => {
    setItems([...items, { description: "", quantity: "", remarks: "" }]);
  };

  // Update item data
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  // Remove an item
  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };
  // get all categories & users states
  const [allStores, setAllStores] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    // Get all stores
    const fetchAllStores = async () => {
      baseURL
        .get(`/stores`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllStores(res.data.data.data);
        })
        .catch((res) => {
          console.log(res?.data?.message, "getAllStores");
        });
    };
    // Get all categories
    const fetchAllCategories = async () => {
      baseURL
        .get(`/categories`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllCategories(res.data.data.data);
        })
        .catch((res) => {
          console.log(res?.data?.message, "getAllCategories");
        });
    };
    // Get all users
    const fetchAllUsers = async () => {
      baseURL
        .get(`/users`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllUsers(res.data.data.data);
        })
        .catch((res) => {
          console.log(res?.data?.message, "getALlUsers");
        });
    };
    fetchAllStores();
    fetchAllUsers();
    fetchAllCategories();
  }, []);
  // Get Profile Info Handler
  useEffect(() => {
    const fetchProfile = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setGeneratedBy(res.data.data.name);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchProfile();
  }, []);
  // Set states of issuer,comapny Name & department
  const getUserInfo = async (user_id) => {
    baseURL
      .get(`/users/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setIssuer(user_id);
        setComapnyName(
          res?.data?.data?.company_name != null
            ? res?.data?.data?.company_name
            : ""
        );
        setDepartment(
          res?.data?.data?.department != null ? res?.data?.data?.department : ""
        );
      })
      .catch((res) => {
        console.log(res?.data?.message);
      });
  };
  // Generate gatePass Handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    await baseURL
      .post(
        `/gatePass`,
        {
          vehicle_no,
          store_id,
          category_id,
          issued_to,
          company_name,
          department,
          items
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          toast.success(res.data.message);
          navigate("/gatepass-listing");
        }
      })
      .catch((res) => {
        toast.error(
          res?.response?.data?.data != null
            ? res?.response?.data?.data[0]
            : "something went wrong"
        );
      });
  };
  // Show specific gate pass
  useEffect(() => {
    // Case of Edit Page
    if (id) {
      const token = localStorage.getItem("token");
      const fetchGatePasses = async () => {
        baseURL
          .get(`/gatePass/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            setVehicleNo(res.data.data.vehicle_no);
            setStoreId(res.data.data.store_id);
            setCategoryId(res.data.data.category_id);
            setIssuer(res.data.data.issued_to.id);
            setComapnyName(res.data.data.company_name);
            setDepartment(res.data.data.department);
            setItems(res.data.data.items);
          })
          .catch((res) => {
            toast.error(res.response.data.message);
          });
      };
      fetchGatePasses();
    } else {
      // Case of Generate GatePass
      setVehicleNo([]);
      setStoreId([]);
      setCategoryId([]);
      setIssuer([]);
      setComapnyName([]);
      setDepartment([]);
      setItems([
        {
          description: "",
          quantity: 0,
          remarks: ""
        }
      ]);
    }
  }, [id]);
  // Update gatePass Handler
  const handleEditSubmit = async (event) => {
    event.preventDefault();
    await baseURL
      .put(
        `/gatePass/${id}`,
        {
          vehicle_no,
          category_id,
          issued_to,
          company_name,
          department,
          items
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          toast.success(res.data.message);
          navigate("/gatepass-listing");
        }
      })
      .catch((res) => {
        toast.error(res?.response?.data?.data ? res?.response?.data?.data[0] : res?.response?.data?.message);
      });
  };
  return (
    <div className="generate-form">
      <Form onSubmit={page == "Generate" ? handleSubmit : handleEditSubmit}>
        <div className="dashboard-main-heading-area dashboard-main-heading-mobile pt-0 mb-4 d-flex">
          <h4 className="mb-0">
            {page == "Generate" ? "Generate GatePass" : "Edit GatePass"}
          </h4>
        </div>
        <Row className="mb-4">
          <Col lg="12">
            <div className="generate-form-content">
              <div className="generate-form-top-wrapper d-flex align-items-center justify-content-between">
                <div className="generate-form-top-info">
                  <h6>
                    Date: <span>{Moment().format("MMMM DD, YYYY")}</span>
                  </h6>
                </div>
                {page == "Edit" && (
                  <div className="generate-form-top-info">
                    <h5>
                      GatePass# <span>{id}</span>
                    </h5>
                  </div>
                )}
                <div className="generate-form-top-info generate-form-vehicle">
                  <Form.Group
                    className="input-wrapper d-flex align-items-center"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Vehicle No<span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LEX-15A-0000"
                      value={vehicle_no}
                      onChange={(e) => setVehicleNo(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="generate-form-input-details">
                <div>
                  <Row>
                    <Col lg="6">
                      <Form.Group
                        className="mb-4 input-wrapper"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Generated By <span className="text-red">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={generated_by}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group
                        className="mb-4 input-wrapper"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Select Store <span className="text-red">*</span>
                        </Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          value={store_id}
                          onChange={(e) => setStoreId(e.target.value)}
                          required
                        >
                          <option disabled value="">
                            Select Store
                          </option>
                          {allStores?.length > 0 &&
                            allStores.map((row, key) => (
                              <option value={row.id}>{row.name}</option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <Form.Group
                        className="mb-4 input-wrapper"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Select GatePass Type{" "}
                          <span className="text-red">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={category_id}
                          onChange={(e) => setCategoryId(e.target.value)}
                          required
                        >
                          <option disabled value="">
                            Select GatePass Type
                          </option>
                          {allCategories?.length > 0 &&
                            allCategories.map((row, key) => (
                              <option value={row.id}>{row.title}</option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group
                        className="mb-4 input-wrapper"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Issued To <span className="text-red">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          required
                          value={issued_to}
                          onChange={(e) => getUserInfo(e.target.value)}
                        >
                          <option disabled value="">
                            Select User
                          </option>
                          {allUsers?.length > 0 &&
                            allUsers.map((row, key) => (
                              <option value={row.id}>{row.name}</option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group
                        className="mb-4 input-wrapper"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Company Name <span className="text-red">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={company_name}
                          onChange={(e) => setComapnyName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group
                        className="input-wrapper"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Department <span className="text-red">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="generate-form-bottom-content-area">
                <div className="generate-form-bottom-content ">
                  <div className="generate-form-bottom-scroll-wrapper">
                    <div className="generate-form-bottom-scroll">
                      <div className="generate-form-bottom-content-info position-relative d-flex align-items-center justify-content-between">
                        <div className="serial-number input-wrapper">
                          <label>Sr No.</label>
                        </div>
                        <div className="bottom-inputs-area d-flex align-items-center justify-content-between">
                          <Form.Group
                            className="input-wrapper generate-description"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Description</Form.Label>
                          </Form.Group>
                          <Form.Group
                            className="input-wrapper generate-quantity"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Quantity</Form.Label>
                          </Form.Group>
                          <Form.Group
                            className="input-wrapper generate-remarks"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Remarks</Form.Label>
                          </Form.Group>
                        </div>
                      </div>

                      {items &&
                        items.map((item, index) => (
                          <div
                            className="generate-form-bottom-content-info generate-form-bottom-feilds position-relative d-flex align-items-center justify-content-between"
                            key={index}
                          >
                            <div className="serial-number input-wrapper">
                              <span className="d-block">{index + 1}</span>
                            </div>
                            <div className="bottom-inputs-area d-flex align-items-center justify-content-between">
                              <Form.Group
                                className="input-wrapper generate-description"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  value={item.description}
                                  required
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                className="input-wrapper generate-quantity"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="number"
                                  value={item.quantity}
                                  required
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                className="input-wrapper generate-remarks"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  value={item.remarks}
                                  required
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      "remarks",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                            </div>
                            {index !== 0 && (
                              <a
                                className="trash-icon-wrapper map-trash"
                                onClick={() => removeItem(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="trash-icon"
                                />
                              </a>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <a className="add-item" onClick={addItem}>
                    <FontAwesomeIcon icon={faPlus} className="plus-icon me-2" />
                    Add Item
                  </a>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col lg="4">
            <Approval />
          </Col> */}
        </Row>
        <div className="generate-buttons d-flex justify-content-end">
          <Button
            className="btn-gray me-sm-3 me-0 mb-sm-0 mb-3"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button className="btn-yellow" type="submit">
            Send For Approval
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default GenerateForm;
