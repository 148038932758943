import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EyeIcon from "../../assets/images/eye-icon.svg";
import PencilIcon from "../../assets/images/pencil-icon.svg";
import TrashIcon from "../../assets/images/trash-icon.svg";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { SpinnerRoundOutlined } from "spinners-react";
const GatepassCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  // create modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // Reset states in case of Add category
  const handleShow = () => {
    setCategoryId();
    setTitle();
    setStatus();
    setDescription();
    setShow(true);
  };
  // update modal
  const [showUpdate, setShowUpdate] = useState(false);
  const handleUpdateClose = () => setShowUpdate(false);
  // Delete modal
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  // GatePass category states
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [parent_id, setParentCategory] = useState("");
  const [status, setStatus] = useState("");
  // handle search
  const [search_name, setSearchName] = useState("");
  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    // Get all categories
    const token = localStorage.getItem("token");
    const fetchCategories = async () => {
      baseURL
        .get(`/categories?parent=1`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setCategories(res.data.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchCategories();
    fetchSubCategories();
  }, [category, search_name]);
  // List of Gatepass Sub-Categories
  const token = localStorage.getItem("token");
  const fetchSubCategories = async (pageNumber = 1) => {
    setIsLoading(true);
    baseURL
      .get(`/categories?childrens=1&search=${search_name}&page=${pageNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setSubCategories(res.data.data.data);
        setPaginateData(res.data.data);
        setIsLoading(false);
      })
      .catch((res) => {
        console.log(res.response.data.message);
      });
  };

  // Create Gatepass Sub-category Handler
  const handleSubmit = async (event) => {
    const token = localStorage.getItem("token");
    event.preventDefault();
    if (parent_id == "") {
      toast.error("Parent category is required");
    } else {
      await baseURL
        .post(
          `/categories`,
          {
            title,
            description,
            parent_id,
            status
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((res) => {
          if (res.data.status == "Success") {
            toast.success(res.data.message);
            setCategory(res.data.data);
            setShow(false);
          }
        })
        .catch((res) => {
          toast.error(res.response.data.data[0]);
        });
    }
  };

  // Update Gatepass Sub-category Handler
  const [categoryId, setCategoryId] = useState([]);
  const handleChangeCategory = (
    id,
    title,
    description,
    status,
    parent_id,
    api
  ) => {
    setCategoryId(id);
    setTitle(title);
    setStatus(status);
    setParentCategory(parent_id);
    setDescription(description);
    api == "update" ? setShowUpdate(true) : setShowModal(true);
  };

  const handleUpdateGatepassCategory = async (event) => {
    const token = localStorage.getItem("token");
    event.preventDefault();
    if (parent_id == "") {
      toast.error("Parent category is required");
    } else {
      await baseURL
        .put(
          `/categories/${categoryId}`,
          {
            title,
            description,
            parent_id,
            status
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((res) => {
          if (res.data.status == "Success") {
            toast.success(res.data.message);
            setCategory(res.data.data);
            setShowUpdate(false);
          }
        })
        .catch((res) => {
          toast.error(res.response.data.data[0]);
        });
    }
  };
  // Delete Gatepass Sub-category Handler
  const handleDeleteCategory = async (event) => {
    const token = localStorage.getItem("token");
    event.preventDefault();
    await baseURL
      .delete(`/categories/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data.status == "Success") {
          toast.success(res.data.message);
          setCategory(res.data.data);
          setShowModal(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };
  // Roles & Permission Section
  const [user, setUser] = useState([]);
  const [allPermissions, setAllPermissions] = useState("");
  useEffect(() => {
    const fetchProfile = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUser(res?.data?.data);
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };
    const fetchPermissions = async () => {
      baseURL
        .get(`/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllPermissions(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchPermissions();
    fetchProfile();
  }, []);

  // Get Permissions of Login User
  const permissions =
    allPermissions &&
    allPermissions.filter((item) => user?.role?.permissions.includes(item.id));
  return (
    <div className="dashboard-table">
      <div className="dashboard-main-heading-area">
        <h4 className="mb-5">GatePass Category</h4>
        <div className="category-buttons d-flex align-items-baseline">
          <Link to={"/gatepass-category"} className="btn-gray mb-0 me-2">
            Category
          </Link>
          <Button className="btn-blue mb-0">Sub-Category</Button>
        </div>
      </div>
      <div className="dashboard-table">
        <div className="dashboard-table-content">
          <div className="dashboard-table-search-area d-flex align-items-center justify-content-between">
            <Form>
              <Form.Group
                className="position-relative input-wrapper"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="search"
                  placeholder="Search by title, parent category"
                  onChange={handleSearch}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="search-icon position-absolute"
                />
              </Form.Group>
            </Form>
            {permissions &&
              permissions.filter(
                (permission) => permission.name === "Add Sub-Category"
              ).length > 0 && (
                <Button className="btn-yellow" onClick={handleShow}>
                  Add New Sub-Category
                </Button>
              )}
          </div>
          <div className="dashboard-table-info">
            <Table hover>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Parent Category</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading == false &&
                  (subCategories.length > 0 ? (
                    subCategories.map((row, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td className="td-width">{row.title}</td>
                        <td>{row?.parent?.title}</td>
                        <td className="td-category-wrapper">
                          {row.description}
                        </td>
                        <td>
                          <div className="profile-content-heading table-checkbox">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={row.status == 1}
                            />
                          </div>
                        </td>
                        <td className="td-width">
                          <div className="edit-table-icons d-flex align-items-center">
                            {permissions &&
                              permissions.filter(
                                (permission) =>
                                  permission.name === "Edit Sub-Category"
                              ).length > 0 && (
                                <a className="eye-icon me-4">
                                  <Image
                                    src={PencilIcon}
                                    className="img-fluid"
                                    alt="aye-icon"
                                    onClick={() =>
                                      handleChangeCategory(
                                        row.id,
                                        row.title,
                                        row.description,
                                        row.status,
                                        row?.parent_id,
                                        "update"
                                      )
                                    }
                                  />
                                </a>
                              )}
                            {permissions &&
                              permissions.filter(
                                (permission) =>
                                  permission.name === "Delete Sub-Category"
                              ).length > 0 && (
                                <a className="trash-icon">
                                  <Image
                                    src={TrashIcon}
                                    className="img-fluid"
                                    alt="trash-icon"
                                    onClick={() =>
                                      handleChangeCategory(
                                        row.id,
                                        row.title,
                                        row.description,
                                        row.status,
                                        row?.parent_id,
                                        "delete"
                                      )
                                    }
                                  />
                                </a>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="5">
                        <p className="text-center" style={{ margin: "0" }}>
                          No Sub Category Found
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {isLoading && (
              <div className="loader-area table-loader">
                <div className="loader-wrapper">
                  <SpinnerRoundOutlined
                    size={50}
                    thickness={100}
                    speed={100}
                    color="#1239A5"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                </div>
              </div>
            )}
            <div class="custom-pagination">
              {categories.length > 0 && (
                <Pagination
                  activePage={
                    paginateData?.current_page ? paginateData?.current_page : 0
                  }
                  itemsCountPerPage={
                    paginateData?.per_page ? paginateData?.per_page : 0
                  }
                  totalItemsCount={
                    paginateData?.total ? paginateData?.total : 0
                  }
                  onChange={(pageNumber) => {
                    fetchSubCategories(pageNumber);
                  }}
                  pageRangeDisplayed={4}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last page"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* create category Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        className="category-modal d-flex align-items-center justify-content-center"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Sub-Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="category-modal-content">
              <Row>
                <Col lg="6">
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Title <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Category Name"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Parent Category <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setParentCategory(e.target.value)}
                    >
                      <option value="">Select Parent Category</option>
                      {categories.length > 0 &&
                        categories.map((row, key) => (
                          <option value={row.id}>{row.title}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg="12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      Description <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter About Category"
                      rows={4}
                      style={{ resize: "none" }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Status <span className="text-red">*</span>
                </Form.Label>
                <div className="form-radios d-flex align-items-center">
                  <div className="form-radio-content me-5 d-flex align-items-center">
                    <Form.Check
                      name="status"
                      type="radio"
                      aria-label="radio 1"
                      value="1"
                      checked
                      onChange={(e) => setStatus(1)}
                    />
                    <Form.Label>Active</Form.Label>
                  </div>
                  <div className="form-radio-content d-flex align-items-center">
                    <Form.Check
                      name="status"
                      type="radio"
                      aria-label="radio 1"
                      value="0"
                      onChange={(e) => setStatus(0)}
                    />
                    <Form.Label>Inactive</Form.Label>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-gray" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn-yellow" type="submit">
              Add Sub-Category
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* update category Modal */}
      <Modal
        show={showUpdate}
        onHide={handleUpdateClose}
        className="category-modal d-flex align-items-center justify-content-center"
      >
        <Form onSubmit={handleUpdateGatepassCategory}>
          <Modal.Header closeButton>
            <Modal.Title>Update Sub-Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="category-modal-content">
              <Row>
                <Col lg="6">
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Title <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Category Name"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Parent Category <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={parent_id}
                      onChange={(e) => setParentCategory(e.target.value)}
                    >
                      <option value="">Select Parent Category</option>
                      {categories.length > 0 &&
                        categories.map((row, key) => (
                          <option value={row.id}>{row.title}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg="12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      Description <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter About Category"
                      rows={4}
                      style={{ resize: "none" }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Status <span className="text-red">*</span>
                </Form.Label>
                <div className="form-radios d-flex align-items-center">
                  <div className="form-radio-content me-5 d-flex align-items-center">
                    <Form.Check
                      name="status"
                      type="radio"
                      id="active"
                      aria-label="radio 1"
                      checked={status == 1}
                      onChange={(e) => setStatus(1)}
                    />
                    <Form.Label>Active</Form.Label>
                  </div>
                  <div className="form-radio-content d-flex align-items-center">
                    <Form.Check
                      name="status"
                      type="radio"
                      id="inactive"
                      aria-label="radio 1"
                      checked={status == 0}
                      onChange={(e) => setStatus(0)}
                    />
                    <Form.Label>Inactive</Form.Label>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-gray" onClick={handleUpdateClose}>
              Cancel
            </Button>
            <Button className="btn-yellow" type="submit">
              Update Sub-Category
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Delete category Modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        className="profile-modal d-flex align-items-center justify-content-center"
      >
        <Modal.Body>
          <h2>Confirm</h2>
          <h4>Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-gray" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button className="btn-gray" onClick={handleDeleteCategory}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GatepassCategory;
