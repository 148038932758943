import React, { useState } from "react";
import { Form, Image } from "react-bootstrap";
import FormLogo from "../../assets/images/form-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    await baseURL
      .post(`forgot-password`, {
        email
      })
      .then((res) => {
        if(res.data.status === 1)
        {
            toast.success(res.data.message);
            setEmail("")
        }
        else
        {
            toast.error(res.data.message);
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };
  return (
    <div className="wrapper">
      <div className="login-form-wrapper">
        <div className="container">
          <a href="/">
            <div className="form-logo text-center">
              <Image src={FormLogo} className="img-fluid" alt="logo" />
            </div>
          </a>

          <Form className="form-box text-center" onSubmit={handleSubmit}>
            <h4>Forgot Password ?</h4>
            <div className="input-group-wrapper">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
            <button className="btn btn-blue" type="submit">
              Submit
            </button>
            <Link className="login-forgot" to="/">
              Back to Login
            </Link>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
