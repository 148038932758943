import React, { useState } from "react";
import { Form, Image } from "react-bootstrap";
import FormLogo from "../../assets/images/form-logo.svg";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const { token } = useParams();
  const handleSubmit = async (event) => {
    event.preventDefault();
    await baseURL
      .post(`reset-password`, {
        password,
        password_confirmation,
        token
      })
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message);
          setPassword("");
          setConfirmPassword("");
        } else {
          toast.error(
            res?.data?.message?.password_confirmation
              ? res?.data?.message?.password_confirmation[0]
              : res?.data?.message
          );
        }
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [ConfirmpasswordType, setConfirmPasswordType] = useState("password");
  const toggleConfirmPassword = () => {
    if (ConfirmpasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  return (
    <div className="wrapper">
      <div className="login-form-wrapper">
        <div className="container">
          <a href="/">
            <div className="form-logo text-center">
              <Image src={FormLogo} className="img-fluid" alt="logo" />
            </div>
          </a>

          <Form className="form-box text-center" onSubmit={handleSubmit}>
            <h4>Reset Password </h4>
            <div className="input-group-wrapper">
              <Form.Group className="mb-3 password-feild" controlId="formBasicEmail">
                <Form.Control
                  type={passwordType}
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="password-eye-icon" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye}/>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="mb-3 password-feild" controlId="formBasicEmail">
                <Form.Control
                  type={ConfirmpasswordType}
                  placeholder="Enter Confirm Password"
                  value={password_confirmation}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <div className="password-eye-icon" onClick={toggleConfirmPassword}>
                  {ConfirmpasswordType === "password" ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye}/>
                  )}
                </div>
              </Form.Group>
            </div>
            <button className="btn btn-blue" type="submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
