import React from "react";
import { Badge, Button, Form, Image, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/dist";
import Moment from "moment";
import { SpinnerRoundOutlined } from "spinners-react";
const GatepassTable = (props) => {
  return (
    <div className="dashboard-table">
      <div className="dashboard-table-content">
        <div className="dashboard-table-search-area d-flex align-items-center justify-content-between">
          <div className="dashboard-main-heading-area pt-0 d-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 mb-3">Generated GatePass</h4>
          </div>
          <Link to="/generate-gatepass" className="btn btn-yellow">
            Generate New
          </Link>
        </div>
        <div className="dashboard-table-info">
          <Table hover>
            <thead>
              <tr>
                <th>GP ID</th>
                <th>Generated By</th>
                <th>Company Name</th>
                <th>Form Type</th>
                {/* <th>Created At</th>
                  <th>Modified at</th> */}
                <th>Approved By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.gatePasses?.length > 0 ? (
                props.gatePasses?.map((row, key) => (
                  <tr>
                    <td>{row?.id}</td>
                    <td>{row?.generated_by}</td>
                    <td>{row?.company_name}</td>
                    <td>{row?.category?.title}</td>
                    {/* <td>{Moment(row?.created_at).format("DD-MM-YYYY")}</td>
                      <td>{Moment(row?.updated_at).format("DD-MM-YYYY")}</td> */}
                    <td>{row?.approvers[0]?.user?.name}</td>
                    <td>
                      {row?.status == 0 && (
                        <Badge className="badge-orange">Pending</Badge>
                      )}
                      {row?.status == 1 && (
                        <Badge className="badge-purple">In Approval</Badge>
                      )}
                      {row?.status == 2 && (
                        <Badge className="badge-sky-blue">Approved</Badge>
                      )}
                      {row?.status == 3 && (
                        <Badge className="badge-pink">Rejected</Badge>
                      )}
                      {row.status == 4 && (
                        <Badge className="badge-green">Completed</Badge>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="20">
                    <p className="text-center" style={{ margin: "0" }}>
                      No GatePass Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default GatepassTable;
