import React, { useState, useEffect } from "react";
import { Button, Image, Form } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import ApproveBadge from "../../assets/images/approve-badge.png";
import RejectBadge from "../../assets/images/reject-badge.png";

const DashboardApproval = (props) => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { id } = useParams();
  // Show specific gate pass
  const [gate_pass_id, setGatePassId] = useState(props.gatePassId);

  const supplier_stock = props.supplier_stock;
  const [user, setUser] = useState([]);
  const [allPermissions, setAllPermissions] = useState("");
  useEffect(() => {
    const fetchProfile = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUser(res?.data?.data);
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };
    const fetchPermissions = async () => {
      baseURL
        .get(`/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllPermissions(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchPermissions();
    fetchProfile();
  }, [props.Approved]);
  const gatePassApprovers = props.gatePassApprovers;
  // Get Permissions of Login User
  const permissions =
    allPermissions &&
    allPermissions.filter((item) => user?.role?.permissions?.includes(item.id));

  // 4 => General Manager, 5 => Floor Manager, 6 => Security
  const role = [4, 5, 6];
  // Check Access for Approved or Rejected
  const CheckApproverRole = role.includes(user.role_id);

  //check login user is approved yet or not
  const CheckApproverUser = gatePassApprovers.filter((approvers) => {
    return approvers.user_id === user.id;
  });

  //check Gatepass is approved from another user with same role
  const CheckApproverApproved = gatePassApprovers.filter((approvers) => {
    return approvers.role_id === user.role_id;
  });

  //check Gatepass is approved from another user with same role
  const CheckApproverStatus = CheckApproverApproved.filter((approvers) => {
    return approvers.gate_pass_status == 1;
  });

  const CheckApproverGatePass = CheckApproverApproved.filter((approvers) => {
    return approvers.gate_pass_id === gate_pass_id;
  });

  // get floor manager, general manager & security approvers

  const FloorManagerApprovers = gatePassApprovers.filter((approvers) => {
    return approvers.role_id == 5;
  });

  const GeneralManagerApprovers = gatePassApprovers.filter((approvers) => {
    return approvers.role_id == 4;
  });

  const SecurityApprovers = gatePassApprovers.filter((approvers) => {
    return approvers.role_id == 6;
  });

  return (
    <div>
      <Form className="dashboard-approval mb-4">
        <div className="dashboard-main-heading-area">
          <h4 className="mb-0">Approvers</h4>
        </div>

        <div className="dashboard-approval-form-details">
          <Form.Group
            className={
              location.pathname.split("/")[1] === "gatepass"
                ? FloorManagerApprovers?.length > 0
                  ? "mb-3 approve-badge-content-wrapper badge-border"
                  : "mb-3 approve-badge-content-wrapper badge-border wait-approve-badge"
                : "mb-3 approve-badge-content-wrapper wait-approve-badge"
            }
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Floor Manager</Form.Label>
            {location.pathname.split("/")[1] === "gatepass" &&
              (FloorManagerApprovers?.length > 0 ? (
                FloorManagerApprovers.map((row, key) => (
                  <div className="approve-badge-content d-flex align-items-center mb-3">
                    <div className="approve-badge-content-img me-4">
                      {row.status == 2 ? (
                        <Image src={RejectBadge} className="img-fluid" />
                      ) : (
                        <Image src={ApproveBadge} className="img-fluid" />
                      )}
                    </div>
                    {row.status == 2 ? (
                      <div className="reject-badge-content-info">
                        <h6>{row?.user?.name}</h6>
                        <p className="mb-0">{row?.created_at}</p>
                      </div>
                    ) : (
                      <div className="approve-badge-content-info">
                        <h6>{row?.user?.name}</h6>
                        <p className="mb-0">{row?.created_at}</p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="approve-badge-content d-flex align-items-center">
                  <div className="approve-badge-content-info">
                    <h5>Waiting For Approval</h5>
                  </div>
                </div>
              ))}
          </Form.Group>
          <Form.Group
            className={
              location.pathname.split("/")[1] === "gatepass"
                ? FloorManagerApprovers?.length > 0
                  ? "mb-3 approve-badge-content-wrapper badge-border"
                  : "mb-3 approve-badge-content-wrapper badge-border wait-approve-badge"
                : "mb-3 approve-badge-content-wrapper wait-approve-badge"
            }
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>General Manager</Form.Label>
            {location.pathname.split("/")[1] === "gatepass" &&
              (GeneralManagerApprovers?.length > 0 ? (
                GeneralManagerApprovers.map((row, key) => (
                  <div className="approve-badge-content d-flex align-items-center mb-3">
                    <div className="approve-badge-content-img me-4">
                      {row.status == 2 ? (
                        <Image src={RejectBadge} className="img-fluid" />
                      ) : (
                        <Image src={ApproveBadge} className="img-fluid" />
                      )}
                    </div>
                    {row.status == 2 ? (
                      <div className="reject-badge-content-info">
                        <h6>{row?.user?.name}</h6>
                        <p className="mb-0">{row?.created_at}</p>
                      </div>
                    ) : (
                      <div className="approve-badge-content-info">
                        <h6>{row?.user?.name}</h6>
                        <p className="mb-0">{row?.created_at}</p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="approve-badge-content d-flex align-items-center">
                  <div className="approve-badge-content-info">
                    <h5>Waiting For Approval</h5>
                  </div>
                </div>
              ))}
          </Form.Group>
          <Form.Group
            className={
              location.pathname.split("/")[1] === "gatepass"
                ? FloorManagerApprovers?.length > 0
                  ? "mb-3 approve-badge-content-wrapper badge-border"
                  : "mb-3 approve-badge-content-wrapper badge-border wait-approve-badge"
                : "mb-3 approve-badge-content-wrapper wait-approve-badge"
            }
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Security</Form.Label>
            {location.pathname.split("/")[1] === "gatepass" &&
              (SecurityApprovers?.length > 0 ? (
                SecurityApprovers.map((row, key) => (
                  <div className="approve-badge-content d-flex align-items-center mb-3">
                    <div className="approve-badge-content-img me-4">
                      {row.status == 2 ? (
                        <Image src={RejectBadge} className="img-fluid" />
                      ) : (
                        <Image src={ApproveBadge} className="img-fluid" />
                      )}
                    </div>
                    {row.status == 2 ? (
                      <div className="reject-badge-content-info">
                        <h6>{row?.user?.name}</h6>
                        <p className="mb-0">{row?.created_at}</p>
                      </div>
                    ) : (
                      <div className="approve-badge-content-info">
                        <h6>{row?.user?.name}</h6>
                        <p className="mb-0">{row?.created_at}</p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="approve-badge-content d-flex align-items-center">
                  <div className="approve-badge-content-info">
                    <h5>Waiting For Approval</h5>
                  </div>
                </div>
              ))}
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Reciever</Form.Label>
          </Form.Group> */}
        </div>
      </Form>
      {user?.role_id == 6 &&
        CheckApproverUser?.length == 0 &&
        CheckApproverApproved?.length == 0 &&
        CheckApproverGatePass?.length == 0 && (
          <div className="checkbox-approve-area">
            <div className="checkbox-approve-wrapper">
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  checked={supplier_stock == 1}
                  onChange={props.supplierStockChange}
                />
                <span class="checkmark"></span>
              </label>
              <h6>Supplier’s stock, physically seen but not counted.</h6>
            </div>
            <div className="checkbox-approve-wrapper">
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  checked={props.reason == 1}
                  onChange={props.ReasonChange}
                />
                <span class="checkmark"></span>
              </label>
              <h6>Other Reason</h6>
            </div>
            {props.reason == 1 && (
              <Form>
                <Form.Group
                  className="input-wrapper checkbox-approve-feilds"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder="reason if any"
                    value={props.reason_description && props.reason_description}
                    onChange={(e) => {
                      props.changeReasonDescription(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            )}
          </div>
        )}
      {user?.role_id != 6 && props.reason == 1 && (
        <div className="checkbox-approve-area">
          <Form>
            <Form.Group
              className="input-wrapper checkbox-approve-feilds"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder="reason if any"
                value={props.reason_description && props.reason_description}
                onChange={(e) => {
                  props.changeReasonDescription(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </div>
      )}
      {location.pathname.split("/")[1] === "gatepass" && user?.role_id != 6 ? (
        // gatePass?.role?.name === user?.role?.name &&
        permissions &&
        permissions.filter(
          (permission) => permission.name == "Approve / Reject GatePass"
        ).length > 0 && 
       CheckApproverStatus?.length == 0 && 
        props.gatePass.status != 3 && (
          <div className="approvers d-flex justify-content-center">
            <Button
              onClick={() => props.HandleGatePassApproval(1)}
              className={props.buttonDisabled ? 'btn-green me-3 disabled' : 'btn-green me-3'} disabled={props.buttonDisabled}
              
            >
              Approved
            </Button>

            <Button
              onClick={() => props.HandleGatePassApproval(2)}
              className={props.buttonDisabled ? 'btn-red me-3 disabled' : 'btn-red me-3'} disabled={props.buttonDisabled}
              
            >
              Rejected
            </Button>
          </div>
        )
      ) : (
        <div className="approvers d-flex justify-content-center">
          {CheckApproverUser?.length == 0 &&
            CheckApproverApproved?.length == 0 &&
            CheckApproverGatePass?.length == 0 && (
              <Button
                onClick={() => props.HandleGatePassApproval(3)}
                className={props.buttonDisabled ? 'btn-green me-3 disabled' : 'btn-green me-3'}
                disabled={props.buttonDisabled}
              >
                Close Request
              </Button>
            )}
        </div>
      )}
    </div>
  );
};

export default DashboardApproval;
