//layouts
import Public from './components/Layouts/Public';
import Private from './components/Layouts/Private';

//components
import Login from './components/LoginForm/index';
import ForgotPassword from './components/ForgotPassword/index';
import ResetPassword from './components/ResetPassword/index';
import Dashboard from './components/Dashboard/index';
import Reports from './components/Reports/index';
import GenerateGatepass from './components/GenerateGatepass/index';
import GatePassListing from './components/GatePassListing/index';
import GatepassView from './components/GatepassView/index';
import UserManagement from './components/UserManagement/index';
import StoreManagement from './components/StoreManagement/index';
import RolesPermission from './components/RolesPermission/index';
import GatepassCategory from './components/GatepassCategory/index';
import GatepassSubCategory from './components/GatepassSubCategory/index';
import Profile from './components/Profile/index';


const routes = [
  { path: "/", exact: true, name: "Login", layout: Public, component: Login },
  { path: "/login", exact: true, name: "Login", layout: Public, component: Login },
  { path: "/forget-password", exact: true, name: "Login", layout: Public, component: ForgotPassword },
  { path: "/reset/password/:token", exact: true, name: "Login", layout: Public, component: ResetPassword },
  { path: "/dashboard", exact: true, name: "Dashboard", layout: Private, component: Dashboard},
  { path: "/generate-gatepass", exact: true, name: "Dashboard", layout: Private, component: GenerateGatepass },
  { path: "/edit-gatepass/:id", exact: true, name: "Dashboard", layout: Private, component: GenerateGatepass },
  { path: "/gatepass-listing", exact: true, name: "Dashboard", layout: Private, component: GatePassListing },
  { path: "/gatepass/:id", exact: true, name: "Dashboard", layout: Private, component: GatepassView },
  { path: "/reports", exact: true, name: "Dashboard", layout: Private, component: Reports },
  { path: "/user-management", exact: true, name: "Dashboard", layout: Private, component: UserManagement },
  { path: "/roles-permission", exact: true, name: "Dashboard", layout: Private, component: RolesPermission },
  { path: "/store-management", exact: true, name: "Dashboard", layout: Private, component: StoreManagement },
  { path: "/gatepass-category", exact: true, name: "Dashboard", layout: Private, component: GatepassCategory },
  { path: "/gatepass-subcategory", exact: true, name: "Dashboard", layout: Private, component: GatepassSubCategory },
  { path: "/profile", exact: true, name: "Dashboard", layout: Private, component: Profile },
]

export default routes;