import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="wrapper">
          <ToastContainer />
          <Routes>
            {routes.map((route, index) => (
              <Route
                exact={route.exact}
                path={route.path}
                element={
                  <route.layout title={route.name}>
                    <route.component />{" "}
                  </route.layout>
                }
                key={index}
              />
            ))}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
