import React, { useState, useEffect } from "react";
import ListingTable from "./ListingTable";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";

const GatePassListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  // Delete modal
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);

  // get all gate passes
  const [AllgatePasses, setAllGatePassData] = useState([]);
  const [gatePasses, setGatePasses] = useState([]);
  const [gatePass, setGatePass] = useState([]); // if changes in this state will update the list
  const [paginateData, setPaginateData] = useState([]);
  const token = localStorage.getItem("token");

  // show and hide filters
  const [show, setShow] = useState(false);

  // handle filter
  const [statuses, setStatuses] = useState([]);
  const handleFilterBadge = async (newFilter) => {
    setShow(true);
    const newList = [...statuses, newFilter];
    setStatuses(newList);
  };

  // Remove filter
  const handleClose = (newFilter) => {
    const newArray = statuses.filter((value) => value !== newFilter);
    setStatuses(newArray);
  };

  // handle search
  const [search_name, setSearchName] = useState("");
  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };
  // Section for roles and permissions
  const [user, setUser] = useState([]);
  const [allPermissions, setAllPermissions] = useState("");
  useEffect(() => {
    const fetchNameAndRole = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUser(res?.data?.data);
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };
   
    const fetchPermissions = async () => {
      baseURL
        .get(`/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllPermissions(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchPermissions();
    fetchNameAndRole();
  }, []);
  // Get Permissions of Login User
  const permissions =
    allPermissions &&
    allPermissions.filter((item) =>
     user?.role?.permissions.includes(item.id)
    );
  // handler gatepass(search, filter & all gatepasses)
  useEffect(() => {
    fetchGatePasses();
  }, [gatePass, statuses, search_name]);

  const fetchGatePasses = async (pageNumber = 1) => {
    setIsLoading(true)
    baseURL
      .get(
        `/gatePass?statuses=${statuses}&search=${search_name}&page=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        setGatePasses(res?.data?.data)
        setPaginateData(res?.data?.meta)
        setAllGatePassData(res?.data?.gate_pass_excel_pdf)
        setIsLoading(false)
      })
      .catch((res) => {
        console.log(res?.response?.data?.message);
      });
  };

  // Delete Gatepass Handler (set Id which is going to be delete)
  const [gatePassId, setGatePassId] = useState([]);
  const handleChangeGatePass = (id) => {
    setGatePassId(id);
    setShowModal(true);
  };

  // Delete Gatepass Handler
  const handleDeleteCategory = async (event) => {
    const token = localStorage.getItem("token");
    event.preventDefault();
    await baseURL
      .delete(`/gatePass/${gatePassId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data.status == "Success") {
          toast.success(res.data.message);
          setGatePass(res.data.data);
          setShowModal(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };

  return (
    <>
      <ListingTable
        gatePasses={gatePasses}
        AllgatePasses={AllgatePasses}
        fetchGatePasses={fetchGatePasses}
        handleChangeGatePass={handleChangeGatePass}
        handleDeleteCategory={handleDeleteCategory}
        showModal={showModal}
        handleModalClose={handleModalClose}
        handleFilterBadge={handleFilterBadge}
        search_name={search_name}
        handleSearch={handleSearch}
        show={show}
        handleClose={handleClose}
        statuses={statuses}
        paginateData={paginateData}
        permissions={permissions}
        isLoading={isLoading}
      />
    </>
  );
};

export default GatePassListing;
