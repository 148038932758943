/* App.js */
import React, { useState, useEffect } from "react";
import baseURL from "../helpers/baseURL";
import * as echarts from "echarts/core";
import ReactECharts from "echarts-for-react";
import { Form, Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import calenderIcon from "../../assets/images/calendar.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { SpinnerRoundOutlined } from "spinners-react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
const Reports = () => {
  const location = useLocation();
  // hide and show calender
  const [showCalender, setShowCalender] = useState(false);
  const handleShow = () => {
    showCalender == false ? setShowCalender(true) : setShowCalender(false);
  };
  // set date and month
  const [date, setDate] = useState("");
  const [month, setMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  const handleDateChange = (date) => {
    setDate(date.toLocaleDateString());
    setMonth(date.toLocaleString("default", { month: "long" }));
  };
  // get all gate passes stats
  const [days, setDays] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [PendingGatePasses, setPendingGatePass] = useState([]);
  const [ApprovedGatePasses, setApprovedGatePass] = useState([]);
  const [RejectedGatePasses, setRejectedGatePass] = useState([]);
  const [CompletedGatePasses, setCompletedGatePass] = useState([]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchGatePassGraphData = async () => {
      setIsLoadingReport(true);
      baseURL
        .get(`/graphData?days=${days}&date=${date}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setGraphData(res.data.data);
          setPendingGatePass(res.data.data.pending);
          setApprovedGatePass(res.data.data.approved);
          setRejectedGatePass(res.data.data.rejected);
          setCompletedGatePass(res.data.data.completed);
          setShowCalender(false);
          setIsLoadingReport(false);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchGatePassGraphData();
  }, [days, date, month]);
  let pending_gate_pass_data = [];
  PendingGatePasses.forEach((el) => {
    pending_gate_pass_data.push([el.status_date, el.status_count]);
  });

  let approved_gate_pass_data = [];
  ApprovedGatePasses.forEach((el) => {
    approved_gate_pass_data.push([el.status_date, el.status_count]);
  });

  let rejected_gate_pass_data = [];
  RejectedGatePasses.forEach((el) => {
    rejected_gate_pass_data.push([el.status_date, el.status_count]);
  });

  let completed_gate_pass_data = [];
  CompletedGatePasses.forEach((el) => {
    completed_gate_pass_data.push([el.status_date, el.status_count]);
  });

  const option = {
    // title: {
    //   left: "center",
    //   text: "Tootip and dataZoom on Mobile Device"
    // },
    legend: {
      top: "bottom",
      data: ["Intention"]
    },
    tooltip: {
      triggerOn: "none",
      position: function (pt) {
        return [pt[0], 130];
      }
    },
    // toolbox: {
    //   left: "center",
    //   itemSize: 25,
    //   top: 55,
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: "none"
    //     },
    //     restore: {}
    //   }
    // },
    xAxis: {
      type: "time",
      axisPointer: {
        value: "2016-10-7",
        snap: true,
        lineStyle: {
          color: "#7581BD",
          width: 2
        },
        label: {
          show: true,
          formatter: function (params) {
            return echarts.format.formatTime("yyyy-MM-dd", params.value);
          },
          backgroundColor: "#7581BD"
        },
        handle: {
          show: true,
          color: "#7581BD",
          size: 32
        }
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: "value",
      axisTick: {
        inside: true
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        inside: true,
        formatter: "{value}\n"
      },
      z: 10
    },
    grid: {
      top: 110,
      left: 15,
      right: 15,
      height: 160
    },
    dataZoom: [
      {
        type: "inside",
        throttle: 50
      }
    ],
    series: [
      {
        name: "Fake Data",
        type: "line",
        smooth: true,
        stack: "a",
        symbol: "circle",
        symbolSize: 5,
        sampling: "average",
        itemStyle: {
          color: "#feb20e"
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(254, 178, 11, 0.55)"
            },
            {
              offset: 1,
              color: "rgba(254, 178, 11, 0)"
            }
          ])
        },
        data: pending_gate_pass_data
      },
      {
        name: "Fake Data",
        type: "line",
        smooth: true,
        symbol: "circle",
        symbolSize: 5,
        sampling: "average",
        itemStyle: {
          color: "#00b767"
        },
        stack: "a",
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(41, 180, 96, 0.25)"
            },
            {
              offset: 1,
              color: "rgba(41, 180, 96, 0)"
            }
          ])
        },
        data: approved_gate_pass_data
      },
      {
        name: "Fake Data",
        type: "line",
        smooth: true,
        stack: "a",
        symbol: "circle",
        symbolSize: 5,
        sampling: "average",
        itemStyle: {
          color: "#ff2d57"
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(241, 67, 99, 0.25)"
            },
            {
              offset: 1,
              color: "rgba(241, 67, 99, 0)"
            }
          ])
        },
        data: rejected_gate_pass_data
      },
      {
        name: "Fake Data",
        type: "line",
        smooth: true,
        stack: "a",
        symbol: "circle",
        symbolSize: 5,
        sampling: "average",
        itemStyle: {
          color: "#b631fb"
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(168, 58, 254, 0.25)"
            },
            {
              offset: 1,
              color: "rgba(168, 58, 254, 0)"
            }
          ])
        },
        data: completed_gate_pass_data
      }
    ]
  };

  // Export to Excel
  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Array of data to be exported
    const pending_data = [["Date", "Status Count"]];
    pending_data.push(...pending_gate_pass_data);

    const approved_data = [["Date", "Status Count"]];
    approved_data.push(...approved_gate_pass_data);

    const rejected_data = [["Date", "Status Count"]];
    rejected_data.push(...rejected_gate_pass_data);

    const completed_data = [["Date", "Status Count"]];
    completed_data.push(...completed_gate_pass_data);
    // Create a new worksheet for each data array
    const worksheet1 = XLSX.utils.aoa_to_sheet(pending_data);
    const worksheet2 = XLSX.utils.aoa_to_sheet(approved_data);
    const worksheet3 = XLSX.utils.aoa_to_sheet(rejected_data);
    const worksheet4 = XLSX.utils.aoa_to_sheet(completed_data);
    // Add the worksheets to the workbook
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet1,
      "Pending GatePass Graph Data"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet2,
      "Approved GatePass Graph Data"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet3,
      "Rejected GatePass Graph Data"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet4,
      "Completed GatePass Graph Data"
    );
    // Generate an XLSX file buffer
    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx"
    });

    // Convert the buffer to a Blob object
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    // Create a download link and trigger the download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.xlsx";
    a.click();

    // Release the object URL resources
    URL.revokeObjectURL(url);
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    PendingGatePasses.push(...ApprovedGatePasses);
    PendingGatePasses.push(...RejectedGatePasses);
    PendingGatePasses.push(...CompletedGatePasses);
    doc.autoTable({
      head: [["Status Date", "Status Count", "Status"]],
      body: PendingGatePasses.map((row) => [
        row.status_date,
        row.status_count,
        row.status == 0
          ? "Pending"
          : row.status == 2
          ? "Approved"
          : row.status == 3
          ? "Rejected"
          : "Completed"
      ])
    });
    doc.save("table.pdf");
  };

  return (
    <div>
      {location.pathname.split("/")[1] === "reports" && (
        <div className="dashboard-main-heading-area dashboard-main-heading-mobile pt-0 mb-4 d-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 mb-3">Report</h4>
          <div className="dashboard-main-filter-area d-flex align-items-center">
            <Button
              className="btn-gray me-sm-3 me-0 mb-sm-0 mb-3"
              onClick={exportToPDF}
            >
              Download PDF
            </Button>
            <Button className="btn-yellow" onClick={exportToExcel}>
              Download CSV
            </Button>
          </div>
        </div>
      )}

      <div className="gatepass-graph-wrapper">
        <Form className="gatepass-graph-form d-sm-flex align-items-center position-relative  justify-content-between">
          {location.pathname.split("/")[1] === "reports" ? (
            <div className="gatepass-graph-form-select d-flex align-items-center">
              <h6 className="mb-0 me-3">Sort by:</h6>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setDays(e.target.value)}
              >
                <option value="">Select Days</option>
                <option value="30">30 days</option>
                <option value="60">60 days</option>
                <option value="120">120 days</option>
              </Form.Select>
            </div>
          ) : (
            <h6>Monthly Stats</h6>
          )}
          <Link
            to="#"
            onClick={handleShow}
            className="gatepass-graph-form-calender d-flex align-items-center"
          >
            <h6 className="me-2">{month}</h6>
            <div className="calender-icon">
              <Image
                src={calenderIcon}
                className="img-fluid"
                alt="calender-icon"
              />
            </div>
          </Link>
          {showCalender && (
            <Calendar value={date} onChange={handleDateChange} />
          )}
        </Form>
        {isLoadingReport ? (
          <div className="loader-area">
            <div className="loader-wrapper">
              <SpinnerRoundOutlined
                size={50}
                thickness={100}
                speed={100}
                color="#1239A5"
                secondaryColor="rgba(0, 0, 0, 0.44)"
              />
            </div>
          </div>
        ) : PendingGatePasses.length != 0 ||
          ApprovedGatePasses.length != 0 ||
          RejectedGatePasses.length != 0 ||
          CompletedGatePasses.length != 0 ? (
          <div>
            <ReactECharts
              option={option}
              echarts={echarts}
              className="gatepass-graph mb-3"
            />
            {location.pathname.split("/")[1] === "reports" && (
              <div className="gatepass-graph-status d-lg-flex align-items-center">
                <div className="gatepass-graph-status-info d-flex align-items-center">
                  <span className="status-green"></span>
                  <h6>Approved</h6>
                </div>
                <div className="gatepass-graph-status-info d-flex align-items-center">
                  <span className="status-red"></span>
                  <h6>Rejected</h6>
                </div>
                <div className="gatepass-graph-status-info d-flex align-items-center">
                  <span className="status-orange"></span>
                  <h6>Pending</h6>
                </div>
                <div className="gatepass-graph-status-info d-flex align-items-center">
                  <span className="status-purple"></span>
                  <h6>Closed</h6>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="gatepass-no-data">
            <h6>No Graph Data</h6>
          </div>
        )}
      </div>
    </div>
  );
};
export default Reports;
