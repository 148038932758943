import React, { useRef, useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import BellIcon from "../../assets/images/icon-bell.svg";
import baseURL from "../helpers/baseURL";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false); 
  // current page location
  const location = useLocation();
  const navigate = useNavigate();
  // create modal
  const [show, setShow] = useState(false);
  // show and hide notification div
  const handleShow = () => {
    show ? setShow(false) : setShow(true)
  };

   // on click outside the component will hide the div
   const ref = useRef(null);
   useEffect(() => {
     const handleClickOutside = (event) => {
       if (ref.current && !ref.current.contains(event.target)) {
         setShow(false);
       }
     };
     document.addEventListener("mousedown", handleClickOutside);
   }, []);
  const token = localStorage.getItem("token");
  // Get Profile Info & Notifications Handler
  const [user, setUser] = useState([]);
  const [role_id, setRoleId] = useState([]);
  const [notifyRoles, setNotifyRoles] = useState([]);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    setIsLoading(true)
    const fetchNameAndRole = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUser(res?.data?.data)
          setRoleId(res?.data?.data?.role_id)
          setIsLoading(false)
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };
    fetchNameAndRole();
  }, []);
  useEffect(() => {
    
    fetchNotifications();
  }, [role_id]);
  const fetchNotifications = async () => {
    await baseURL
      .get(`/notification?role_id=${role_id}&roles=0`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setNotifications(res?.data?.data);
      })
      .catch((res) => {
        console.log(res?.response?.data?.message);
      });
  };

  //check login user is approved yet or not
  const CheckUser = notifications?.filter((notification) => {
    return notification.user_id === user.id;
  });

  // Handle Notification
  const handleNotification = async (notificationId,gatePassId) => {
    await baseURL
      .put(`/notification/${notificationId}`,
      {
      },{
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data.status == "Success") {
          setShow(false);
          navigate(`/gatepass/${gatePassId}`)
          fetchNotifications()
        }
      })
      .catch((res) => {
        console.log(res.response);
      });
  };
  return (
    <div className="dashboard-main-heading-area mb-4 d-flex align-items-center justify-content-between">
      {location.pathname.split("/")[1] === "dashboard" && !isLoading &&(
        <h4 className="mb-0">{"Good Day, " + user?.name}</h4>
      )}
      <div></div>
      <div className="notification-wrapper position-relative" ref={ref}>
        <a
          href="#"
          onClick={handleShow}
          className="bell-icon position-relative"
        >
          <Image src={BellIcon} className="img-fluid" alt="bell-icon" />

          {notifications?.length > 0 && CheckUser?.length == 0 &&<span className="notification-active"></span>}
        </a>
        {show && (
          <div className="notification-box position-absolute" >
            {notifications?.length > 0 && CheckUser?.length == 0? (
              notifications.map((row, key) => (
                <div className="notification-box-content">
                  <Link
                    onClick={() => handleNotification(row?.id,row?.gate_pass_id)}
                    // to={`/gatepass/${row.gate_pass_id}`}
                    className="notification-box-content-info"
                  >
                    <h5>{row?.title}</h5>
                    <p>{row?.message}</p>
                  </Link>
                </div>
              ))
            ) : (
              <p className="mb-0">No notification found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
