import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row, Table } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import PencilIcon from "../../assets/images/pencil-icon.svg";
import TrashIcon from "../../assets/images/trash-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import baseURL from "../helpers/baseURL";
import authenticated from "../helpers/authHelper";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
const UserManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [page, setPage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUserId();
    setName();
    setUserName();
    setEmail();
    setPhoneNumber();
    setRoleId();
    setCompanyName();
    setDepartment();
    setPage(null);
    setShow(true);
  };
  // Delete modal
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  // get all users and roles states
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  // Users States
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [department, setDepartment] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [status, setStatus] = useState("");
  const [role_id, setRoleId] = useState("");
  const [user, setUser] = useState([]); // update states of list whenever user is add, eidt or delete
  // handle search
  const [search_name, setSearchName] = useState("");
  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };
  // get all users and roles
  const [profile, setProfile] = useState([]);
  const [allPermissions, setAllPermissions] = useState("");
  useEffect(() => {
    const fetchRoles = async () => {
      baseURL
        .get(`/roles`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setRoles(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    const fetchProfile = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setProfile(res?.data?.data);
        })
        .catch((res) => {
          console.log(res?.response?.data?.message);
        });
    };
    const fetchPermissions = async () => {
      baseURL
        .get(`/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setAllPermissions(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchPermissions();
    fetchProfile();
    fetchUsers();
    fetchRoles();
  }, [user, search_name]);

  const fetchUsers = async () => {
    setIsLoading(true)
    baseURL
      .get(`/users?search=${search_name}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setUsers(res.data.data.data);
        setIsLoading(false)
      })
      .catch((res) => {
        console.log(res.response.data.message);
      });
  };
  // Create User Handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    await baseURL
      .post(
        `/users`,
        {
          name,
          username,
          email,
          phone_number,
          company_name,
          department,
          password,
          password_confirmation,
          status,
          role_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(res.data.message);
          setUser(res.data.data);
          setShow(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });

  };
  // set states in case of edit & delete
  const [userId, setUserId] = useState([]);
  const handleChangeCategory = (
    api,
    id,
    name,
    username,
    email,
    phone_number,
    role_id,
    company_name,
    department,
    status,
    password,
    password_confirmation
  ) => {
    setPage(api);
    setUserId(id);
    setName(name);
    setUserName(username);
    setEmail(email);
    setPhoneNumber(phone_number);
    setRoleId(role_id);
    setCompanyName(company_name);
    setDepartment(department);
    setStatus(status);
    setPassword(password);
    setConfirmPassword(password_confirmation);
    api === "update" ? setShow(true) : setShowModal(true);
  };
  // update User Handler
  const handleUpdateUser = async (event) => {
    event.preventDefault();
    await baseURL
      .put(
        `/users/${userId}`,
        {
          name,
          username,
          email,
          phone_number,
          company_name,
          department,
          password,
          password_confirmation,
          status,
          role_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(res.data.message);
          setUser(res.data.data);
          setShow(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };
  // Delete User Handler
  const handleDeleteCategory = async (event) => {
    event.preventDefault();
    await baseURL
      .delete(`/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(res.data.message);
          setUser(res.data.data);
          setShowModal(false);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.data[0]);
      });
  };

  // Get Permissions of Login User
  const permissions =
    allPermissions &&
    allPermissions.filter((item) =>
      profile?.role?.permissions.includes(item.id)
  );
  
  return (
    <div className="dashboard-table">
      <div className="dashboard-main-heading-area dashboard-main-heading-mobile mb-md-5 mb-4 pt-0 d-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 mb-3">User Management</h4>
        {permissions &&
          permissions.filter((permission) => permission.name === "Create User")
            .length > 0 && (
            <Button className="btn-yellow" onClick={handleShow}>
              Add New User
            </Button>
          )}
      </div>
      <div className="dashboard-table">
        <div className="dashboard-table-content">
          <div className="dashboard-table-search-area d-flex align-items-center justify-content-between">
            <Form>
              <Form.Group
                className="position-relative input-wrapper"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="search"
                  placeholder="Search user by Name, Role, Email...."
                  onChange={handleSearch}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="search-icon position-absolute"
                />
              </Form.Group>
            </Form>
          </div>
          <div className="dashboard-table-info">
            <Table>
              <thead>
                <tr>
                  <th>Display Name</th>
                  <th>User Role</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  {/* <th>Created Date</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading == false && (users &&
                  users.map((row, key) => (
                    <tr>
                      {/* <td>
                        <label class="checkbox-container">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td> */}
                      <td>{row?.name}</td>
                      <td>{row?.role?.name}</td>
                      <td className="td-width">{row?.email}</td>
                      <td className="td-width">{row?.phone_number}</td>
                      {/* <td className="td-width">{Moment(row?.created_at).format("DD-MM-YYYY")}</td> */}
                      <td>
                        <div className="profile-content-heading table-checkbox">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={row?.status == 1}
                          />
                        </div>
                      </td>
                      <td className="td-width">
                        <div className="edit-table-icons d-flex align-items-center">
                          {permissions &&
                            permissions.filter(
                              (permission) => permission.name === "Edit User"
                            ).length > 0 && (
                              <a className="eye-icon me-4">
                                <Image
                                  src={PencilIcon}
                                  className="img-fluid"
                                  alt="aye-icon"
                                  onClick={() =>
                                    handleChangeCategory(
                                      "update",
                                      row.id,
                                      row.name,
                                      row.username,
                                      row.email,
                                      row.phone_number,
                                      row.role_id,
                                      row.company_name,
                                      row.department,
                                      row.status,
                                      row.password,
                                      row.password_confirmation
                                    )
                                  }
                                />
                              </a>
                            )}
                          {permissions &&
                            permissions.filter(
                              (permission) => permission.name === "Delete User"
                            ).length > 0 && (
                              <a className="trash-icon">
                                <Image
                                  src={TrashIcon}
                                  className="img-fluid"
                                  alt="trash-icon"
                                  onClick={() =>
                                    handleChangeCategory("delete", row.id)
                                  }
                                />
                              </a>
                            )}
                        </div>
                      </td>
                    </tr>
                  )))}
              </tbody>
            </Table>
            {isLoading && (
              <div className="loader-area table-loader">
                <div className="loader-wrapper">
                  <SpinnerRoundOutlined
                    size={50}
                    thickness={100}
                    speed={100}
                    color="#1239A5"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* create or edit user Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        className="category-modal d-flex align-items-center justify-content-center"
      >
        <Form onSubmit={page === "update" ? handleUpdateUser : handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {page === "update" ? "Update User" : "Add New User"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="category-modal-content">
              <Row>
                <Col lg="6">
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Name <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      User Name <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg="12">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Email <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Phone Number <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={phone_number}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Role <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setRoleId(e.target.value)}
                      value={role_id}
                    >
                      <option value="">Select Role</option>
                      {roles.length > 0 &&
                        roles.map((row, key) => (
                          <option value={row.id}>{row.name}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Company Name <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={company_name}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Department <span className="text-red">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Password {page != "update" && <span className="text-red">*</span>}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group
                    className="mb-4 input-wrapper"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Confirm Password {page != "update" && <span className="text-red">*</span>}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      value={password_confirmation}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Status <span className="text-red">*</span>
                </Form.Label>
                <div className="form-radios d-flex align-items-center">
                  <div className="form-radio-content me-5 d-flex align-items-center">
                    <Form.Check
                      name="status"
                      type="radio"
                      id="active"
                      aria-label="radio 1"
                      value="1"
                      checked={status == 1}
                      onChange={(e) => setStatus(1)}
                    />
                    <Form.Label>Active</Form.Label>
                  </div>
                  <div className="form-radio-content d-flex align-items-center">
                    <Form.Check
                      name="status"
                      type="radio"
                      id="inactive"
                      aria-label="radio 1"
                      value="0"
                      checked={status == 0}
                      onChange={(e) => setStatus(0)}
                    />
                    <Form.Label>Inactive</Form.Label>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-gray" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn-yellow" type="submit">
              {page === "update" ? "Update User" : "Add User"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Delete category Modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        className="profile-modal d-flex align-items-center justify-content-center"
      >
        <Modal.Body>
          <h2>Confirm</h2>
          <h4>Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-gray" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button className="btn-gray" onClick={handleDeleteCategory}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserManagement;
