import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import ProfilePlus from "../../assets/images/profile-plus.svg";
import ModalTick from "../../assets/images/modal-tick.svg";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import Sidebar from "../includes/Sidebar";
import Notification from "../Notifications";
import { Link } from "react-router-dom/dist";
import { SpinnerRoundOutlined } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  //Success modal show and hide
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // Profile Info states
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [sidebarImage, setSidebarImage] = useState(null);
  const [name, setName] = useState("");
  const [role_id, setRoleId] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [department, setDepartment] = useState("");
  const [system_notification, setNotification] = useState("");
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [success_msg, setSuccessMsg] = useState("");
  // Handle File Upload
  const handleFileUpload = (event) => {
    setImage(null);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setImage(file);
    }
  };
  // Upload Latest state
  useEffect(() => {}, [image]);

  // Get Profile Info Handler
  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const fetchProfile = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setName(res.data.data.name);
          setRoleId(res.data.data.role_id);
          setEmail(res.data.data.email);

          setImage(res.data.data.image);

          setUserName(res.data.data.username);
          setPhoneNumber(res.data.data.phone_number);
          setCompanyName(res.data.data.company_name);
          setDepartment(res.data.data.department);
          setNotification(res.data.data.system_notification);
          setIsLoading(false);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchProfile();
  }, []);

  // Update Profile Handler
  const handleSubmit = async (event) => {
    const token = localStorage.getItem("token");
    event.preventDefault();
    if (typeof image === "string") {
      await baseURL
        .post(
          `/profile`,
          {
            name,
            username,
            phone_number,
            company_name,
            department,
            system_notification,
            current_password,
            password,
            password_confirmation
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then((res) => {
          setShow(true);
          setSidebarImage(res.data.data.image);
          setSuccessMsg(res.data.message);
        })
        .catch((res) => {
          toast.error(res.response.data.data ? res.response.data.data[0] : res.response.data?.message);
        });
    } else {
      await baseURL
        .post(
          `/profile`,
          {
            name,
            username,
            phone_number,
            company_name,
            department,
            system_notification,
            image,
            current_password,
            password,
            password_confirmation
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then((res) => {
          setShow(true);
          setSidebarImage(res.data.data.image);
          setSuccessMsg(res.data.message);
        })
        .catch((res) => {
          toast.error(res.response.data.data ? res.response.data.data[0] : res.response.data.message);
        });
    }
  };
  // Update Password Handler
  const handleChangePassword = async (event) => {
    const token = localStorage.getItem("token");
    await baseURL
      .post(
        `/updatePassword`,
        {
          current_password,
          password,
          password_confirmation
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((res) => {
        toast.error(res?.response?.data?.data[0]);
        // toast.error(res.response.data.data);
      });
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [CurrentPasswordType, setCurrentPasswordType] = useState("password");
  const toggleCurrentPassword = () => {
    if (CurrentPasswordType === "password") {
      setCurrentPasswordType("text");
      return;
    }
    setCurrentPasswordType("password");
  };
  const [ConfirmPasswordType, setConfirmPasswordType] = useState("password");
  const toggleConfirmPassword = () => {
    if (ConfirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  return (
    <div className="dashboard-wrapper position-relative">
      <Sidebar image={image} sidebarImage={sidebarImage} name={name} />
      <div className="dashboard-main">
        <Notification />
        {isLoading ? (
          <div className="loader-area">
            <div className="loader-wrapper">
              <SpinnerRoundOutlined
                size={50}
                thickness={100}
                speed={100}
                color="#1239A5"
                secondaryColor="rgba(0, 0, 0, 0.44)"
              />
            </div>
          </div>
        ) : (
          <div className="profile">
            <div className="dashboard-main-heading-area mb-4 d-flex align-items-center justify-content-between">
              <h4 className="mb-0">Profile</h4>
              <div className="generate-buttons">
                <Link to={"/dashboard"} className="btn-gray me-3">
                  Back
                </Link>
              </div>
            </div>
            <Form className="profile-content" onSubmit={handleSubmit}>
              <div className="profile-content-heading">
                <h4 className="mb-0">Personal Information</h4>
              </div>
              <div className="profile-content-details generate-form-input-details">
                <div className="profile-content-img position-relative">
                  <Image
                    src={
                      imageUrl
                        ? imageUrl
                        : `${process.env.REACT_APP_SITE_URL}/storage/profile_images/${image}`
                    }
                    className="img-fluid"
                    alt="profile-img"
                  />
                  <span className="profile-plus position-absolute">
                    <Image
                      src={ProfilePlus}
                      className="img-fluid"
                      alt="profile-plus"
                    />
                  </span>
                  <input
                    type="file"
                    name="profile"
                    className="upload-input"
                    onChange={handleFileUpload}
                  />
                </div>
                <Row>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Full Name <span className="text-red">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Username <span className="text-red">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Email ID <span className="text-red">*</span>
                      </Form.Label>
                      <Form.Control type="email" value={email} readOnly />
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Phone Number <span className="text-red">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="profile-content-heading">
                <h4 className="mb-0">Business Information</h4>
              </div>
              <div className="profile-content-details generate-form-input-details">
                <Row>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Company Name <span className="text-red">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={company_name}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Department <span className="text-red">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="profile-content-heading d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Turn On System Notifications</h4>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={system_notification}
                  onChange={(e) => setNotification(e.target.checked)}
                />
              </div>
              <div className="profile-content-heading">
                <h4 className="mb-0">Change Password</h4>
              </div>
              <div className="profile-content-details generate-form-input-details">
                <Row>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper password-feild"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type={CurrentPasswordType}
                        placeholder="xxxxxxxx"
                        value={current_password}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <div
                        className="password-eye-icon"
                        onClick={toggleCurrentPassword}
                      >
                        {CurrentPasswordType === "password" ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper password-feild"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={passwordType}
                        placeholder="xxxxxxxx"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="password-eye-icon"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group
                      className="mb-4 input-wrapper password-feild"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type={ConfirmPasswordType}
                        placeholder="xxxxxxxx"
                        value={password_confirmation}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div
                        className="password-eye-icon"
                        onClick={toggleConfirmPassword}
                      >
                        {ConfirmPasswordType === "password" ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="profile-last-buttons d-flex align-items-center justify-content-center">
                {/* <Button
                  className="btn-blue me-sm-4 me-0 mb-sm-0 mb-3"
                  onClick={handleChangePassword}
                >
                  Update Password
                </Button> */}
                <Button className="btn-yellow" type="submit">
                  Save Changes
                </Button>
              </div>
            </Form>
            <Modal
              show={show}
              onHide={handleClose}
              className="profile-modal d-flex align-items-center justify-content-center"
            >
              <Modal.Body>
                <div className="modal-tick">
                  <Image
                    src={ModalTick}
                    className="img-fluid"
                    alt="modal-tick"
                  />
                </div>
                <h4>{success_msg}</h4>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn-gray" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
