import React, { useState } from "react";
import { Form, Image } from "react-bootstrap";
import FormLogo from "../../assets/images/form-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import baseURL from "../helpers/baseURL";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role_id, setNewRole] = useState(3);
  // Login Handler
  const handleChangeRole = (newRole) => {
    setNewRole(newRole);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await baseURL
      .post(`auth/login`, {
        role_id,
        email,
        password
      })
      .then((res) => {
        if (res.data.status === "Success") {
          localStorage.setItem("token", res.data.data.token);
          navigate("/dashboard");
        }
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div className="wrapper">
      <div className="login-form-wrapper">
        <div className="container">
          <a href="/">
            <div className="form-logo text-center">
              <Image src={FormLogo} className="img-fluid" alt="logo" />
            </div>
          </a>
          {/* <div className="form-links-wrapper">
            <div className="form-links-content">
              <a href="#" onClick={() => handleChangeRole(3)} className={role_id == 3 && "active"}>
                Vendor Login
              </a>
              <a href="#" onClick={() => handleChangeRole(2)} className={role_id == 2 && "active"}>Employee Login</a>
              <a href="#" onClick={() => handleChangeRole(4)} className={role_id == 4 && "active"}>Management Login</a>
              <a href="#" onClick={() => handleChangeRole(1)} className={role_id == 1 && "active"}>Admin Login</a>
              <a href="#" onClick={() => handleChangeRole(6)} className={role_id == 6 && "active"}>Security Login</a>
            </div>
          </div> */}

          <Form className="form-box text-center" onSubmit={handleSubmit}>
            <h4>Log In To Metro GatePass System</h4>
            <div className="input-group-wrapper">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="example@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="mb-3 password-feild">
                <Form.Control
                  type={passwordType}
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="password-eye-icon" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye}/>
                  )}
                </div>
              </Form.Group>
            </div>
            <button className="btn btn-blue" type="submit">
              Log In
            </button>
            <Link className="login-forgot" to="/forget-password">
              Forgot Password?
            </Link>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
