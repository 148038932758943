import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  Image,
  Table,
  Modal
} from "react-bootstrap";
import FilterIcon from "../../assets/images/filter-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import EyeIcon from "../../assets/images/eye-icon.svg";
import PencilIcon from "../../assets/images/pencil-icon.svg";
import { Link } from "react-router-dom/dist";
import TrashIcon from "../../assets/images/trash-icon.svg";
import Moment from "moment";
import Pagination from "react-js-pagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { SpinnerRoundOutlined } from "spinners-react";
const GatepassTable = (props) => {
  const [show, setShow] = useState(true);
  const handleChange = () => {
    show ? setShow(false) : setShow(true);
  };
  const gatePasses = props.AllgatePasses;
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["Generated By", "Company Name", "Form Type", "Approved By", "status"]
      ],
      body: props.AllgatePasses.map((row) => [
        row.generated_by,
        row.company_name,
        row.category?.title,
        row?.approvers[0]?.user?.name,
        row.status == 0
          ? "Pending"
          : row.status == 1
          ? "In Approval"
          : row.status == 2
          ? "Approved" 
          : row.status == 3
          ? "Rejected" 
          : "Completed"
      ])
    });
    doc.save("gatepass.pdf");
  };

 
  let gate_pass_data = [];
  gatePasses.forEach((el) => {
    gate_pass_data.push([el.generated_by, el.company_name, el.category?.title, el.approvers[0]?.user?.name, el.status == 0
      ? "Pending"
      : el.status == 1
      ? "In Approval"
      : el.status == 2
      ? "Approved" 
      : el.status == 3
      ? "Rejected" 
      : "Completed"]);
  });
  
  const exportToExcel = () => {

    // Array of data to be exported
    const tableData = [["Generated By", "Company Name", "Form Type", "Approved By", "status"]];
    tableData.push(...gate_pass_data);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "GatePass Listing");

    // Generate an XLSX file buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });

    // Convert the buffer to a Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    // Save the file
    saveAs(blob, "GatePass.xlsx");
  };

  return (
    <div className="dashboard-table">
      <div className="dashboard-main-heading-area mb-md-5 mb-4 pt-0 d-flex align-items-center justify-content-between">
        <h4 className="mb-0">GatePass Listing</h4>
        <div className="dashboard-main-filter-area dashboard-main-filter-area-show d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">Export</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={generatePDF}>
                As PDF
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={exportToExcel}
              >
                As Excel
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <a
            href="#"
            className="filter-area d-flex align-items-center"
            onClick={handleChange}
          >
            <div className="filter-cion me-2">
              <Image src={FilterIcon} className="img-fluid" alt="filter-icon" />
            </div>
            Filters
          </a>
        </div>
        <div className="dashboard-main-filter-mobile-area d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faFileExport} className="export-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">As PDF</Dropdown.Item>
              <Dropdown.Item href="#/action-2">As Excel</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <a href="#" className="filter-area d-flex align-items-center" onClick={handleChange}>
            <div className="filter-cion me-2">
              <Image src={FilterIcon} className="img-fluid" alt="filter-icon" />
            </div>
          </a>
        </div>
      </div>
      <div className="dashboard-table">
        {show && (
          <div className="dashboard-table-tabs">
            {props.show && props.statuses.includes(0) ? (
              <Badge className="badge-highlight">
                Pending
                <FontAwesomeIcon
                  icon={faXmark}
                  className="badge-cross-icon"
                  onClick={() => props.handleClose(0)}
                />
              </Badge>
            ) : (
              <Badge
                className="badge-orange"
                onClick={() => props.handleFilterBadge(0)}
              >
                Pending
              </Badge>
            )}
            {props.show && props.statuses.includes(1) ? (
              <Badge className="badge-highlight">
                In Approval
                <FontAwesomeIcon
                  icon={faXmark}
                  className="badge-cross-icon"
                  onClick={() => props.handleClose(1)}
                />
              </Badge>
            ) : (
              <Badge
                className="badge-purple"
                onClick={() => props.handleFilterBadge(1)}
              >
                In Approval
              </Badge>
            )}
            {props.show && props.statuses.includes(2) ? (
              <Badge className="badge-highlight">
                Approved
                <FontAwesomeIcon
                  icon={faXmark}
                  className="badge-cross-icon"
                  onClick={() => props.handleClose(2)}
                />
              </Badge>
            ) : (
              <Badge
                className="badge-sky-blue"
                onClick={() => props.handleFilterBadge(2)}
              >
                Approved
              </Badge>
            )}
            {props.show && props.statuses.includes(3) ? (
              <Badge className="badge-highlight">
                Rejected
                <FontAwesomeIcon
                  icon={faXmark}
                  className="badge-cross-icon"
                  onClick={() => props.handleClose(3)}
                />
              </Badge>
            ) : (
              <Badge
                className="badge-pink"
                onClick={() => props.handleFilterBadge(3)}
              >
                Rejected
              </Badge>
            )}
            {props.show && props.statuses.includes(4) ? (
              <Badge className="badge-highlight">
                Completed
                <FontAwesomeIcon
                  icon={faXmark}
                  className="badge-cross-icon"
                  onClick={() => props.handleClose(4)}
                />
              </Badge>
            ) : (
              <Badge
                className="badge-green"
                onClick={() => props.handleFilterBadge(4)}
              >
                Completed
              </Badge>
            )}
          </div>
        )}
        <div className="dashboard-table-content" id="my-export-table">
          <div className="dashboard-table-search-area d-flex align-items-center justify-content-between">
            <Form>
              <Form.Group
                className="position-relative input-wrapper"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="search"
                  placeholder="Search by Company, Form Type...."
                  values={props.search_name}
                  onChange={props.handleSearch}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="search-icon position-absolute"
                />
              </Form.Group>
            </Form>
            {props.permissions &&
              props.permissions.filter(
                (permission) => permission.name === "Generate GatePass"
              ).length > 0 && (
                <Link to="/generate-gatepass" className="btn btn-yellow">
                  Generate New
                </Link>
              )}
          </div>

          <div className="dashboard-table-info">
            <Table hover>
              <thead>
                <tr>
                  <th>GP ID</th>
                  <th>Generated By</th>
                  <th>Company Name</th>
                  <th>Form Type</th>
                  {/* <th>Created At</th>
                  <th>Modified at</th> */}
                  <th>Approved By</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {props.isLoading == false &&
                  (props.gatePasses.length > 0 ? (
                    props.gatePasses.map((row, key) => (
                      <tr>
                        <td>{row?.id}</td>
                        <td>{row?.generated_by}</td>
                        <td>{row?.company_name}</td>
                        <td>{row?.category?.title}</td>
                        {/* <td>{Moment(row?.created_at).format("DD-MM-YYYY")}</td>
                      <td>{Moment(row?.updated_at).format("DD-MM-YYYY")}</td> */}
                        <td>{row?.approvers[0]?.user?.name}</td>
                        <td>
                          {row?.status == 0 && (
                            <Badge className="badge-orange">Pending</Badge>
                          )}
                          {row?.status == 1 && (
                            <Badge className="badge-purple">In Approval</Badge>
                          )}
                          {row?.status == 2 && (
                            <Badge className="badge-sky-blue">Approved</Badge>
                          )}
                          {row?.status == 3 && (
                            <Badge className="badge-pink">Rejected</Badge>
                          )}
                          {row?.status == 4 && (
                            <Badge className="badge-green">Completed</Badge>
                          )}
                        </td>
                        <td className="td-width">
                          <div className="edit-table-icons d-flex align-items-center">
                            {props.permissions &&
                              props.permissions.filter(
                                (permission) =>
                                  permission.name === "View GatePass"
                              ).length > 0 && (
                                <Link
                                  to={`/gatepass/${row.id}`}
                                  className="eye-icon me-4"
                                >
                                  <Image
                                    src={EyeIcon}
                                    className="img-fluid"
                                    alt="aye-icon"
                                  />
                                </Link>
                              )}
                            {props.permissions &&
                              props.permissions.filter(
                                (permission) =>
                                  permission.name === "Edit GatePass"
                              ).length > 0 && (
                                <Link
                                  to={`/edit-gatepass/${row.id}`}
                                  className="eye-icon me-4"
                                >
                                  <Image
                                    src={PencilIcon}
                                    className="img-fluid"
                                    alt="aye-icon"
                                  />
                                </Link>
                              )}
                            {props.permissions &&
                              props.permissions.filter(
                                (permission) =>
                                  permission.name === "Delete GatePass"
                              ).length > 0 && (
                                <Link className="trash-icon">
                                  <Image
                                    src={TrashIcon}
                                    className="img-fluid"
                                    alt="trash-icon"
                                    onClick={() =>
                                      props.handleChangeGatePass(row.id)
                                    }
                                  />
                                </Link>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="20">
                        <p className="text-center" style={{ margin: "0" }}>
                          No GatePass Found
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {props.isLoading && (
              <div className="loader-area table-loader">
                <div className="loader-wrapper">
                  <SpinnerRoundOutlined
                    size={50}
                    thickness={100}
                    speed={100}
                    color="#1239A5"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                </div>
              </div>
            )}
            <div className="custom-pagination">
              {props.gatePasses.length > 0 && (
                <Pagination
                  activePage={
                    props.paginateData?.current_page
                      ? props.paginateData?.current_page
                      : 0
                  }
                  itemsCountPerPage={
                    props.paginateData?.per_page
                      ? props.paginateData?.per_page
                      : 0
                  }
                  totalItemsCount={
                    props.paginateData?.total ? props.paginateData?.total : 0
                  }
                  onChange={(pageNumber) => {
                    props.fetchGatePasses(pageNumber);
                  }}
                  pageRangeDisplayed={4}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last page"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={props.showModal}
        onHide={props.handleModalClose}
        className="profile-modal d-flex align-items-center justify-content-center"
      >
        <Modal.Body>
          <h2>Confirm</h2>
          <h4>Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-gray" onClick={props.handleModalClose}>
            Cancel
          </Button>
          <Button className="btn-gray" onClick={props.handleDeleteCategory}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GatepassTable;
