import React, { useState, useEffect } from "react";
import Stats from "./Stats";
import GatepassTable from "./GatepassTable";
import baseURL from "../helpers/baseURL";
import { SpinnerRoundOutlined } from "spinners-react";
import Reports from "../Reports";
const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  // get all gate passes
  const [gatePasses, setGatePasses] = useState([]);
  const [stats, setStats] = useState([]);
  const [userRole, setUserRole] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    // Get Profile Info Handler
    const fetchProfile = async () => {
      await baseURL
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setUserRole(res.data.data.role_id);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    const fetchGatePasses = async () => {
      setIsLoading(true)
      baseURL
        .get(`/gatePass?limit=5`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setGatePasses(res.data.gate_pass_excel_pdf);
          setIsLoading(false)
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    const fetchDashboardStats = async () => {
      baseURL
        .get(`/dashboard/statistics`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setStats(res.data.data);
        })
        .catch((res) => {
          console.log(res.response.data.message);
        });
    };
    fetchProfile();
    fetchDashboardStats();
    fetchGatePasses();
  }, []);
  return isLoading ? (
    <div className="loader-area">
      <div className="loader-wrapper">
        <SpinnerRoundOutlined
          size={50}
          thickness={100}
          speed={100}
          color="#1239A5"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    </div>
  ) :(
    <>
      <Stats stats={stats} userRole={userRole}/>
      {(userRole == 1 || userRole == 4 || userRole == 5) && <Reports/>}
      <GatepassTable gatePasses={gatePasses} isLoading={isLoading}/>
    </>
  );
};

export default Dashboard;
